import { TeamResponse, TeamType, TeamTypeEnum } from '../types/team';

export const getTeamTypeDisplayValue = (type: TeamType | string) =>
  type === TeamTypeEnum.Program
    ? 'SCDM'
    : type === TeamTypeEnum.Department
    ? 'Prime'
    : type === TeamTypeEnum.Subcontractor
    ? 'Subcontractor'
    : type === TeamTypeEnum.Engagement
    ? 'Partner'
    : type === TeamTypeEnum.Team
    ? 'Project'
    : type;

export const getTeamTypeOrderLevel = (type: string) =>
  type === TeamTypeEnum.Program
    ? 0
    : type === TeamTypeEnum.Department
    ? 1
    : type === TeamTypeEnum.Subcontractor
    ? 2
    : type === TeamTypeEnum.Engagement
    ? 3
    : type === TeamTypeEnum.Team
    ? 4
    : 99;

export const teamDeepFilter = (
  originalTeams: TeamResponse[],
  startParentId: number,
  filterToType: TeamType = 'Team'
): TeamResponse[] => {
  const childTeams = originalTeams.filter((x) => x.parentIds.includes(startParentId));

  if (childTeams.filter((x) => x.type === filterToType).length === childTeams.length) return childTeams;

  return childTeams.flatMap((x) => teamDeepFilter(originalTeams, x.id, filterToType));
};

export const getChildrenIds = (teams: TeamResponse[], teamType: TeamType, childTeamType: TeamType = 'Team') =>
  teams
    .filter((t) => t.type === teamType)
    .map((t) => ({
      parent: t.name,
      teamIds: teamDeepFilter(teams, t.id, childTeamType).map((x) => +x.id),
    }));
