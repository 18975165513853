import { Area } from '@ant-design/charts';
import { AreaConfig } from '@ant-design/charts/es';
import { Col, Empty } from 'antd';
import { chain, groupBy } from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import {
  ABOVE_TARGET,
  BELOW_TARGET,
  COLOR_ABOVE_TARGET,
  COLOR_BELOW_TARGET,
  COLOR_ON_TARGET,
  DATE_ID_FORMAT,
} from '../../common/constants';
import { Card } from '../../components/Card';
import { ProgramTrendingDataContext } from '../../contexts/ProgramTrendingDataContext';
import { TeamContext } from '../../contexts/TeamContext';
import { IProgramTrendingData, TeamScore } from '../../types/metrics';
import { customTargetLegend } from '../../utils/chartUtils';
import { IData } from './EngagementTrending';
import { getChildrenIds } from '../../utils/teamUtils';

const BELOW_TARGET_LABEL = 'Below Target';
const ON_TARGET_LABEL = 'On Target';
const ABOVE_TARGET_LABEL = 'Above Target';

const TrendingChart = ({ scoreData }: { scoreData: TeamScore[] }) => {
  if (!scoreData?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const aggregateData = groupBy(scoreData, (item) => item.dateId);

  const chartData: IData[] = [];

  Object.keys(aggregateData).forEach((item) => {
    const belowTarget: IData = {
      month: moment(item, DATE_ID_FORMAT).format('MMM YY'),
      key: BELOW_TARGET_LABEL,
      value: aggregateData[item].filter((item) => item.score < BELOW_TARGET).length,
    };
    const onTarget: IData = {
      month: moment(item, DATE_ID_FORMAT).format('MMM YY'),
      key: ON_TARGET_LABEL,
      value: aggregateData[item].filter((item) => item.score >= BELOW_TARGET && item.score <= ABOVE_TARGET).length,
    };
    const aboveTarget: IData = {
      month: moment(item, DATE_ID_FORMAT).format('MMM YY'),
      key: ABOVE_TARGET_LABEL,
      value: aggregateData[item].filter((item) => item.score > ABOVE_TARGET).length,
    };
    chartData.push(belowTarget);
    chartData.push(onTarget);
    chartData.push(aboveTarget);
  });
  const config: AreaConfig = {
    data: chartData,
    xField: 'month',
    yField: 'value',
    seriesField: 'key',
    label: {
      style: {
        fill: '#aaa',
      },
    },
    legend: {
      position: 'top',
      flipPage: false,
      items: customTargetLegend,
    },
    color: [COLOR_BELOW_TARGET, COLOR_ON_TARGET, COLOR_ABOVE_TARGET],
  };

  return <Area {...config} />;
};

export const SubcontractorTrending = () => {
  const { teams } = useContext(TeamContext);
  const { teamDatas } = useContext<IProgramTrendingData>(ProgramTrendingDataContext);

  if (!teamDatas) return <></>;

  return (
    <>
      {chain(teamDatas)
        .groupBy(
          (s) =>
            getChildrenIds(teams, 'Subcontractor').find((x) => x.teamIds.includes(s.teamId))?.parent ??
            'No Subcontractor'
        )
        .map((groupData, key) => (
          <Col
            key={key}
            xs={24}
            xl={12}
            // xxl={8}
          >
            <Card title={`${key} Performance Trends`}>
              <TrendingChart scoreData={groupData} />
            </Card>
          </Col>
        ))
        .value()}
    </>
  );
};
