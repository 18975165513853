import { Row, Col, DatePicker, Button } from 'antd';
import moment from 'moment';

import { ProgramScore } from '../charts/ProgramScore';
import { Card } from '../components/Card';
import { SolutionSetScores } from '../charts/SolutionSetScores';
import { MonthOverMonth } from '../charts/MonthOverMonth';
import { DATE_ID_FORMAT, MIN_DATE_ID, MONTH_PICKER_FORMAT } from '../common/constants';
import { ProgramDataContext, ProgramDataContextProvider } from '../contexts/ProgramDataContext';
import { useHistory, useLocation } from 'react-router';
import { EngagementHeatmap } from '../charts/EngagementHeatmap';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { DownloadOutlined } from '@ant-design/icons';
import downloadFileFromBlob from '../utils/downloadFileFromBlob';
import { ExportScore } from '../apis/scoreClient';
import Title from 'antd/lib/typography/Title';
import { SubcontractorProgramPerformances } from '../charts/SubcontractorProgramPerformances';
import { TeamType, TeamTypeWithDetails } from '../types/team';
import { getTeamTypeDisplayValue } from '../utils/teamUtils';

interface IProgramDatePickerProps {
  dateId?: string;
}

const ProgramDatePicker = ({ dateId }: IProgramDatePickerProps) => {
  const history = useHistory();
  const { programScore } = useContext(ProgramDataContext);
  const date = dateId || programScore?.dateId;
  const month = date ? moment(`${date}`, DATE_ID_FORMAT) : null;
  return (
    <DatePicker
      value={month}
      disabledDate={(current) => current.diff(moment(MIN_DATE_ID)) < 0}
      onChange={(e) => {
        e && history.push(`/dashboard?dateId=${e.format(DATE_ID_FORMAT)}`);
      }}
      format={MONTH_PICKER_FORMAT}
      picker="month"
      allowClear={false}
    />
  );
};

export const ProgramDashboard = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const dateId = params.get('dateId');
  const month = dateId ? moment(dateId, DATE_ID_FORMAT) : null;
  const [isExporting, setIsExporting] = useState<Record<TeamType, boolean>>();

  const ExportData = async (type: string) => {
    try {
      setIsExporting((prevVal) => ({ ...prevVal, [type]: true }));
      const result = await ExportScore(Number(dateId), type);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
    } finally {
      setIsExporting((prevVal) => ({ ...prevVal, [type]: false }));
    }
  };

  return (
    <ProgramDataContextProvider month={month?.toDate()}>
      <Helmet>
        <title>Subcontractor Scoring</title>
      </Helmet>
      <div className="page-home page-container">
        <section className="section-container section-padding">
          <div className="section-title-container">
            <span className="section-title">SCDM Overview</span>
            <ProgramDatePicker dateId={dateId} />
          </div>
          <Row gutter={24} justify="center">
            <Col className="chart-Program" xs={24} sm={12} xxl={5}>
              <Card title="Subcontractor Score">
                <ProgramScore />
              </Card>
            </Col>
            <Col className="chart-Program" xs={24} sm={12} xxl={7}>
              <Card title="Scores by Subcontractor">
                <SolutionSetScores teamType={'Subcontractor'} />
              </Card>
            </Col>

            <SubcontractorProgramPerformances />
          </Row>
          <Row gutter={24} justify="center">
            {(['Subcontractor', 'Team'] as TeamType[]).map((type) => (
              <Col key={`${type}-improvement`} className="chart-Program" xxl={12} xl={24}>
                <Card>
                  <Row>
                    <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                      Top {getTeamTypeDisplayValue(type)} Improvement
                    </Title>
                    <Button onClick={() => ExportData(type)} loading={isExporting?.[type] ?? false}>
                      <DownloadOutlined />
                    </Button>
                  </Row>
                  <Row>
                    <MonthOverMonth type={type} />
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </section>
        <EngagementHeatmap month={month?.toDate()} />
      </div>
    </ProgramDataContextProvider>
  );
};
