import { Line } from '@ant-design/charts';
import { Empty } from 'antd';
import { chain, minBy } from 'lodash';
import moment from 'moment';
import { useContext, useMemo } from 'react';

import { fetchCategory } from '../apis/fetchCategory';
import { DATE_ID_FORMAT } from '../common/constants';
import { ProjectDataContext } from '../contexts/ProjectDataContext';
import { useFetch } from '../hooks/useFetch';

export const ProjectTrending = () => {
  const { scores } = useContext(ProjectDataContext);
  const { data: categoryList } = useFetch(() => fetchCategory());
  const endDateId = moment().format(DATE_ID_FORMAT);
  const startDateId = moment().subtract(12, 'months').format(DATE_ID_FORMAT);

  const data = useMemo(() => {
    const scoresWithin12Months = scores.filter((item) => +endDateId >= item.dateId && item.dateId >= +startDateId);

    return chain(scoresWithin12Months)
      .orderBy((item) => item.dateId)
      .flatMap((item) => {
        const month = moment(item.dateId, DATE_ID_FORMAT).format('MMM YY');
        if (item.categoryData.length === 0) return [];

        return (categoryList ?? []).map((cat) => ({
          date: month,
          category: cat.name,
          value: Number(item.categoryData?.find((x) => x.categoryId === cat.id)?.score?.toFixed(2)),
        }));
      })
      .filter((item) => item.value != null)
      .value();
  }, [categoryList, endDateId, scores, startDateId]);

  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Line
      data={data}
      height={300}
      yField="value"
      xField="date"
      seriesField="category"
      point={{
        shape: 'diamond',
        size: 5,
      }}
      yAxis={{
        max: 1.05,
        tickInterval: 0.05,
        min: Math.min(minBy(data, (item) => item.value)?.value, 0.5),
      }}
      label={{}}
      // annotations={targetAnnotation(scores.length)}
      legend={{
        position: 'top',
        flipPage: false,
      }}
    />
  );
};
