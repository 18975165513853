import { Drawer, Button, Grid, Dropdown, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { StyledMenu } from './Menu.styled';
import classNames from 'classnames';
import { TeamSelect } from './TeamSelect';
import { TeamContext } from '../contexts/TeamContext';
import { StyledMenuItem } from './PageHeader.styled';
import { MenuItem } from '../types/menu';
import { getTeamTypeDisplayValue } from '../utils/teamUtils';

const MainMenuItems = [
  {
    to: '/dashboard',
    text: 'Dashboard',
  },
];
const AdminMenuItems: MenuItem[] = [
  // {
  //   to: '/targets',
  //   text: 'Target Configuration',
  // },
  // {
  //   to: '/admin/importteams',
  //   text: 'Import Projects',
  // },
  {
    to: '/admin/importactual',
    text: 'Import Actual',
  },
  {
    to: '/admin/importtarget',
    text: 'Import Target',
  },
  // {
  //   to: '/admin/import-sentiment',
  //   text: 'Import Monthly Sentiment',
  // },
  // {
  //   to: '/admin/import-teamweight',
  //   text: 'Import Project Weight',
  // },
  {
    to: '/admin/kpi-management',
    text: 'KPI Management',
  },
  {
    to: '/admin/kpi-definition',
    text: 'KPI Definition',
  },
  {
    to: '/admin/teams',
    text: 'Projects Management',
  },
  {
    to: '/admin/users',
    text: 'Users Management',
  },
];
const MobileMenuItems: MenuItem[] = [
  {
    to: '/trending',
    text: 'SCDM Trends',
  },
  // {
  //   to: '/npsdashboard',
  //   text: 'NPS Analysis',
  // },
  // {
  //   to: '/statistic',
  //   text: 'Statistics',
  // },
];
interface IProps {
  isMobileSize: boolean;
  isAdmin: boolean;
  dateId: string;
}
export const LeftMenu = ({ isMobileSize, isAdmin, dateId }: IProps) => {
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();

  const history = useHistory();
  const location = useLocation();
  const [selectedTeam, setSelectedTeam] = useState<number>(undefined);
  const { teams } = useContext(TeamContext);

  useEffect(() => {
    location.pathname !== history.location.pathname && setSelectedTeam(undefined);
  }, [location]);

  const handleNavigate = (value: number) => {
    const team = teams.find((item) => item.id === value);
    if (!team) {
      return;
    }

    setSelectedTeam(value);
    setVisible(false);
    history.push(`/${getTeamTypeDisplayValue(team.type).toLowerCase()}/${value}${dateId ? `?dateId=${dateId}` : ''}`);
  };
  let MenuItems = [];
  if (isMobileSize || isAdmin) {
    MenuItems = MenuItems.concat(MainMenuItems);
  }

  if (isMobileSize) {
    MenuItems = MenuItems.concat(MobileMenuItems);
  }
  if (isAdmin) {
    MenuItems = MenuItems.concat(AdminMenuItems);
  }

  return (
    <>
      {(isMobileSize || isAdmin) && (
        <>
          <Button icon={<MenuOutlined />} onClick={() => setVisible(true)} size="middle" />
          <Drawer
            className="app-main-menu"
            title="MENU"
            placement="left"
            closable={false}
            onClose={() => setVisible(false)}
            visible={visible}
          >
            {isMobileSize && (
              <TeamSelect
                value={selectedTeam}
                className="user-container-team-select-mobile"
                showSearch
                type={['Team', 'Engagement', 'Subcontractor']}
                onSelect={handleNavigate}
                defaultActiveFirstOption={false}
                placeholder="Search for partner project(s)"
                style={{ width: '235px', margin: '10px' }}
              />
            )}
            <StyledMenu>
              {MenuItems.map((menuitem) => {
                if (menuitem?.children?.length > 0)
                  return (
                    <Dropdown
                      key="dropdown"
                      overlay={
                        <Menu>
                          {menuitem.children.map((child) => (
                            <StyledMenuItem
                              key={child.text}
                              className={location.pathname.includes(`${menuitem.to}${child.to}`) ? 'item-active' : ''}
                            >
                              <Link
                                className={
                                  location.pathname.includes(`${menuitem.to}${child.to}`) ? 'item-text-active' : ''
                                }
                                to={`${menuitem.to}${child.to}`}
                              >
                                {child.text}
                              </Link>
                            </StyledMenuItem>
                          ))}
                        </Menu>
                      }
                      placement="bottomLeft"
                      arrow
                    >
                      <Button
                        key={menuitem.text}
                        type="text"
                        className={classNames({ 'is-active': location.pathname.includes(menuitem.to) })}
                      >
                        {menuitem.text}
                      </Button>
                    </Dropdown>
                  );
                return (
                  <Button
                    key={menuitem.text}
                    type="text"
                    className={classNames({ 'is-active': pathname === menuitem.to })}
                  >
                    <Link to={menuitem.to} onClick={() => setVisible(false)}>
                      {menuitem.text}
                    </Link>
                  </Button>
                );
              })}
            </StyledMenu>
          </Drawer>
        </>
      )}
    </>
  );
};
