import { LegendItem } from '@antv/g2/esm/interface';
import { Annotation } from '@antv/g2plot/esm/types/annotation';
import {
  ABOVE_TARGET,
  ABOVE_TARGET_LABEL,
  BELOW_TARGET,
  BELOW_TARGET_LABEL,
  COLOR_ABOVE_TARGET,
  COLOR_BELOW_TARGET,
  COLOR_ON_TARGET,
  DETRACTOR_COLOR,
  DETRACTOR_LABEL,
  DETRACTOR_SCORE,
  ON_TARGET_LABEL,
  PASSIVE_COLOR,
  PASSIVE_LABEL,
  PROMOTER_COLOR,
  PROMOTER_LABEL,
  PROMOTER_SCORE,
  SENTIMENT_POS_LABEL,
  SENTIMENT_NEUTRAL_LABEL,
  SENTIMENT_NEG_LABEL,
  SENTIMENT_POS_COLOR,
  SENTIMENT_NEUTRAL_COLOR,
  SENTIMENT_NEG_COLOR,
  GROWING_LABEL,
  BUILDING_LABEL,
  SOLID_LABEL,
  STRONG_LABEL,
  LEADING_LABEL,
  GROWING_COLOR,
  BUILDING_COLOR,
  SOLID_COLOR,
  STRONG_COLOR,
  LEADING_COLOR,
} from '../common/constants';

export const getTargetColor = (label: string) => {
  switch (label) {
    case BELOW_TARGET_LABEL:
      return COLOR_BELOW_TARGET;
    case ON_TARGET_LABEL:
      return COLOR_ON_TARGET;
    case ABOVE_TARGET_LABEL:
      return COLOR_ABOVE_TARGET;
    default:
      return '';
  }
};

export const getTargetLabel = (score: number) =>
  score < BELOW_TARGET ? BELOW_TARGET_LABEL : score < ABOVE_TARGET ? ON_TARGET_LABEL : ABOVE_TARGET_LABEL;

export const customTargetLegend: LegendItem[] = [
  {
    name: 'Below Target',
    value: 'Below Target',
    marker: {
      symbol: 'square',
      style: {
        fill: COLOR_BELOW_TARGET,
      },
    },
  },
  {
    name: 'On Target',
    value: 'On Target',
    marker: {
      symbol: 'square',
      style: {
        fill: COLOR_ON_TARGET,
      },
    },
  },
  {
    name: 'Above Target',
    value: 'Above Target',
    marker: {
      symbol: 'square',
      style: {
        fill: COLOR_ABOVE_TARGET,
      },
    },
  },
];

export const getNpsCategory = (nps: number) => {
  if (nps == null) {
    return 'N/A';
  }

  if (nps > PROMOTER_SCORE) {
    return PROMOTER_LABEL;
  }

  if (nps < DETRACTOR_SCORE) {
    return DETRACTOR_LABEL;
  }

  return PASSIVE_LABEL;
};

export const getTssCategory = (tss: number) => {
  if (tss > 0 && tss <= 1) return GROWING_LABEL;

  if (tss <= 2) return BUILDING_LABEL;

  if (tss <= 3) return SOLID_LABEL;

  if (tss <= 4) return STRONG_LABEL;

  return LEADING_LABEL;
};

export const getNpsColor = (label: string) => {
  switch (label) {
    case PROMOTER_LABEL:
      return PROMOTER_COLOR;
    case PASSIVE_LABEL:
      return PASSIVE_COLOR;
    case DETRACTOR_LABEL:
      return DETRACTOR_COLOR;

    default:
      return '';
  }
};

export const getTssColor = (label: string) => {
  switch (label) {
    case GROWING_LABEL:
      return GROWING_COLOR;
    case BUILDING_LABEL:
      return BUILDING_COLOR;
    case SOLID_LABEL:
      return SOLID_COLOR;
    case STRONG_LABEL:
      return STRONG_COLOR;
    case LEADING_LABEL:
      return LEADING_COLOR;
    default:
      return '';
  }
};

export const getSentimentCategory = (posNeg: number) => {
  switch (posNeg) {
    case 1:
      return SENTIMENT_POS_LABEL;
    case -1:
      return SENTIMENT_NEG_LABEL;
    default:
      return SENTIMENT_NEUTRAL_LABEL;
  }
};

export const getSentimentColor = (label: string) => {
  switch (label) {
    case SENTIMENT_POS_LABEL:
      return SENTIMENT_POS_COLOR;
    case SENTIMENT_NEG_LABEL:
      return SENTIMENT_NEG_COLOR;
    default:
      return SENTIMENT_NEUTRAL_COLOR;
  }
};

export const targetAnnotation = (length: number): Annotation[] => [
  {
    type: 'line',
    start: [-0.5, ABOVE_TARGET],
    end: [length, ABOVE_TARGET],
    text: {
      content: 'Above Target',
      offsetX: 70,
      offsetY: -2,
      style: { textAlign: 'right' },
    },
    style: {
      stroke: COLOR_ABOVE_TARGET,
      lineDash: [2, 2],
    },
  },
  {
    type: 'line',
    start: [-0.5, BELOW_TARGET],
    end: [length, BELOW_TARGET],
    text: {
      content: 'Below Target',
      offsetX: 68,
      offsetY: 15,
      style: { textAlign: 'right' },
    },
    style: {
      stroke: COLOR_BELOW_TARGET,
      lineDash: [2, 2],
    },
  },
];
