import { Gauge } from '@ant-design/charts';
import { Empty } from 'antd';
import { useContext } from 'react';
import { GaugeRangeDefaultConfigs } from '../common/constants';
import { ProjectDataContext } from '../contexts/ProjectDataContext';

export const ProjectScore = () => {
  const { currentMonth } = useContext(ProjectDataContext);

  if (!currentMonth || currentMonth.score == null) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return (
    <div className="gauge-container">
      <Gauge
        height={300}
        range={{
          ...GaugeRangeDefaultConfigs,
        }}
        percent={Number(currentMonth?.score.toFixed(2))}
        statistic={{
          content: {
            formatter: (datum, data) => {
              return `${datum.percent}`;
            },
          },
        }}
      />
      {currentMonth?.scoreMoM != null && (
        <div className="gauge-text">
          <span className={`kpi-badge ${currentMonth.scoreMoM < 0 ? 'kpi-decrease' : 'kpi-increase'}`}>
            <span className="kpi-indicator">{currentMonth.scoreMoM < 0 ? '▼' : '▲'}</span>
            <span className="kpi-value">{currentMonth.scoreMoM}%</span>
            <span className="kpi-unit">(MOM)</span>
          </span>
        </div>
      )}
    </div>
  );
};
