import { Col, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { fetchCategory } from '../apis/fetchCategory';
import { DepartmentTreding } from '../charts/trending/DepartmentTrending';
import { ProgramNPSTrending } from '../charts/trending/ProgramNPSTrending';
import { PSFlowTrending } from '../charts/trending/PSFlowTrending';
import { SubcontractorTrending } from '../charts/trending/SubcontractorTrending';
import { Card } from '../components/Card';
import { ProgramTrendingDataContextProvider } from '../contexts/ProgramTrendingDataContext';
import { useFetch } from '../hooks/useFetch';
import { CategoryResponse } from '../types/metrics';

type Option = {
  label: string;
  value: number;
};
export const ProgramTrendingDashBoard = () => {
  const { data: categoryList } = useFetch(() => fetchCategory());
  const [selectedCategory, setSelectedCategory] = useState<number>();
  const [options, setOptions] = useState<Option[]>();

  const optionCategory = (categoryList: CategoryResponse[]) => {
    let options = categoryList?.map((category) => ({
      label: category.name,
      value: category.id,
    }));
    return options;
  };
  useEffect(() => {
    if (categoryList) {
      let options = optionCategory(categoryList);
      setOptions(options);
      setSelectedCategory(options[0].value);
    }
  }, [categoryList]);
  return (
    <ProgramTrendingDataContextProvider>
      <Helmet>
        <title>SCDM Trends</title>
      </Helmet>
      <div className="page-home page-container">
        <section className="section-container">
          <div className="section-title-container">
            <span className="section-title">SCDM Trends</span>
          </div>
          <Row gutter={24} className="row fullwitdh-chart" justify="center" wrap={true}>
            <Col
              xs={24}
              xl={12}
              // xxl={8}
            >
              <Card title="Subcontractor Score Trends">
                <DepartmentTreding teamType={'Subcontractor'} />
              </Card>
            </Col>
            <Col
              xs={24}
              xl={12}
              // xxl={8}
            >
              <Card title="Partner Score Trends">
                <DepartmentTreding teamType={'Engagement'} />
              </Card>
            </Col>

            <SubcontractorTrending />

            <Col
              xs={24}
              xl={12}
              // xxl={8}
            >
              <Card title="SCDM NPS Trends">
                <ProgramNPSTrending />
              </Card>
            </Col>
            <Col
              xs={24}
              xl={12}
              // xxl={8}
            >
              <Card title="Score Flow Trends">
                {categoryList && (
                  <>
                    <Select
                      value={selectedCategory}
                      options={options}
                      onChange={(value: number) => setSelectedCategory(value)}
                    />
                    <PSFlowTrending teamtype={'Program'} type={'Score'} categoryId={selectedCategory} />
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </section>
      </div>
    </ProgramTrendingDataContextProvider>
  );
};
