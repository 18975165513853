import { Input, Table } from 'antd';
import { orderBy } from 'lodash';
import { ColumnType } from 'antd/lib/table';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ProgramDataContext } from '../contexts/ProgramDataContext';
import { TeamScore } from '../types/metrics';
import { StyledLinkCell } from './StyledLinkCell.styled';
import { Link } from 'react-router-dom';
import IconExternalLink from '../icons/IconExternalLink';
import { SearchOutlined } from '@ant-design/icons';
import { TeamType } from '../types/team';
import { getTeamTypeDisplayValue } from '../utils/teamUtils';

const CustomCell = ({ value }: any) => {
  return (
    <div style={{ color: value < 0 ? 'red' : 'green' }}>
      {value == null ? '' : isNaN(value) ? '' : value < 0 ? `▼${value}%` : `▲${value}%`}
    </div>
  );
};

const MyCustomCell = (value: any) => <CustomCell value={value} />;

const NameCell = (text: string, record: TeamScore, projectLink = 'project') => (
  <StyledLinkCell>
    <Link target="_blank" to={`/${projectLink}/${record.teamId}`}>
      {text}
      <IconExternalLink />
    </Link>
  </StyledLinkCell>
);

const functionFilter = (selectedKeys, setSelectedKeys, confirm) => {
  return (
    <Input
      placeholder="Type search name"
      value={selectedKeys[0]}
      onChange={(data) => {
        setSelectedKeys(data.target.value ? [data.target.value] : []);
        confirm({ closeDropdown: false });
      }}
      onPressEnter={() => {
        confirm();
      }}
      onBlur={() => {
        confirm();
      }}
    />
  );
};

const buildColumns = (projectLink?: string): ColumnType<TeamScore>[] => [
  {
    dataIndex: 'name',
    title: 'Name',
    ellipsis: true,
    width: 250,
    render: (text, record) => NameCell(text, record, projectLink),
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) =>
      functionFilter(selectedKeys, setSelectedKeys, confirm),
    // eslint-disable-next-line react/display-name
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) => {
      return record.name.toLowerCase().includes(value.toString().toLowerCase());
    },
  },
  {
    dataIndex: 'score',
    align: 'center',
    title: 'Score',
    sorter: (a, b) => a.score - b.score,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 80,
    render: (val: number) => val?.toFixed(2),
  },
  {
    dataIndex: 'scoreMoM',
    align: 'center',
    title: 'Change',
    render: MyCustomCell,
    sorter: (a, b) => a.scoreMoM - b.scoreMoM,
    defaultSortOrder: 'descend',
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 90,
  },
];

const pageSize = 5;

interface IProps {
  type: TeamType;
}

export const MonthOverMonth = ({ type }: IProps) => {
  const { subcontractorScores, engagementScores, teamScores } = useContext(ProgramDataContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileSize, setMobileSize] = useState(false);
  const breakpoint = 415;
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    setMobileSize(width < breakpoint);
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const data = useMemo(() => {
    let data = teamScores;
    if (type === 'Subcontractor') data = subcontractorScores;
    if (type === 'Engagement') data = engagementScores;

    return data?.filter((item) => item.score != null);
  }, [engagementScores, subcontractorScores, teamScores, type]);
  const [page, setPage] = useState(1);

  const cols: ColumnType<TeamScore>[] = [
    {
      title: '#',
      render: (_, __, index) => index + (page - 1) * pageSize + 1,
      width: 50,
      align: 'center',
    },
    ...buildColumns(getTeamTypeDisplayValue(type).toLowerCase()),
  ];

  return (
    <div className="month-over-month-container scroll-table">
      <Table
        rowKey="name"
        bordered
        dataSource={orderBy(data, (item) => item.scoreMoM, 'desc').map((value, index) => ({ ...value, index }))}
        columns={cols}
        rowClassName="editable-row"
        pagination={{
          onChange: (page) => setPage(page),
          simple: mobileSize ? true : false,
          defaultPageSize: 5,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};
