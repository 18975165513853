import { SearchOutlined } from '@ant-design/icons';
import { Empty, Input, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { orderBy } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { EngagementDataContext } from '../contexts/EngagementDataContext';
import { TeamContext } from '../contexts/TeamContext';
import IconExternalLink from '../icons/IconExternalLink';
import { TeamScore } from '../types/metrics';
import { teamDeepFilter } from '../utils/teamUtils';
import { StyledLinkCell } from './StyledLinkCell.styled';
import { TeamTypeEnum } from '../types/team';

const CustomCell = ({ value }: any) => {
  return (
    <div style={{ color: value < 0 ? 'red' : 'green' }}>
      {value == null ? '' : isNaN(value) ? '' : value < 0 ? `▼${value}%` : `▲${value}%`}
    </div>
  );
};

const MyCustomCell = (value: any) => <CustomCell value={value} />;

const NameCell = (text, record: TeamScore) => (
  <StyledLinkCell>
    <Link to={`/project/${record.teamId}?dateId=${record.dateId}`}>
      {text}
      <IconExternalLink />
    </Link>
  </StyledLinkCell>
);

const columns: ColumnType<TeamScore>[] = [
  {
    dataIndex: 'score',
    align: 'center',
    title: 'Score',
    sorter: (a, b) => a.score - b.score,
    defaultSortOrder: 'descend',
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 90,
    render: (val: number) => val?.toFixed(2),
  },
  {
    dataIndex: 'scoreMoM',
    align: 'center',
    title: 'Change',
    render: MyCustomCell,
    sorter: (a, b) => a.scoreMoM - b.scoreMoM,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 100,
  },
];

const pageSize = 4;

export const EngagementTeamScore = () => {
  const { teamId, teamScores } = useContext(EngagementDataContext);
  const { teams } = useContext(TeamContext);

  const childTeamIds = useMemo(
    () =>
      teams.find((item) => item.id === teamId).type === TeamTypeEnum.Subcontractor
        ? teamDeepFilter(teams, +teamId).map((x) => x.id)
        : [],
    [teams, teamId]
  );
  const data = useMemo(
    () =>
      teamScores?.filter(
        (item) => (childTeamIds.length === 0 || childTeamIds.includes(item.teamId)) && item.score != null
      ),
    [childTeamIds, teamScores]
  );

  const [page, setPage] = useState(1);

  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const functionFilter = (selectedKeys, setSelectedKeys, confirm, clearFilters) => {
    return (
      <Input
        placeholder="Type search name"
        value={selectedKeys[0]}
        onChange={(data) => {
          setSelectedKeys(data.target.value ? [data.target.value] : []);
          confirm({ closeDropdown: false });
        }}
        onPressEnter={() => {
          confirm();
        }}
        onBlur={() => {
          confirm();
        }}
      />
    );
  };
  const cols: ColumnType<TeamScore>[] = [
    {
      title: '#',
      render: (_, __, index) => index + (page - 1) * pageSize + 1,
      width: 50,
      align: 'center',
    },
    {
      dataIndex: 'name',
      title: 'Project Name',
      ellipsis: true,
      render: NameCell,
      onCell: (data) => ({
        title: data.name,
      }),
      filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) =>
        functionFilter(selectedKeys, setSelectedKeys, confirm, clearFilters),
      // eslint-disable-next-line react/display-name
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toString().toLowerCase());
      },
    },
    ...columns,
  ];

  const tableData = orderBy(data, (item) => item.score, 'desc').map((value, index) => ({ ...value, index }));

  return (
    <div className="month-over-month-container">
      <Table
        rowKey="name"
        bordered
        dataSource={tableData}
        columns={cols}
        rowClassName="editable-row"
        pagination={{
          onChange: (page) => setPage(page),
          pageSize,
        }}
      />
    </div>
  );
};
