/* eslint-disable react/display-name */
import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  TablePaginationConfig,
  Tag,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { createTeam, deleteTeam, updateTeam } from '../apis/teamClient';
import { DUPLICATE_TEAM_MESSAGE, GENERIC_MESSAGE, USER_PAGE_NUMBER } from '../common/constants';
import { EditableColumn, EditableTable } from '../components/EditableTable';
import { TeamSelect } from '../components/TeamSelect';
import { TeamContext } from '../contexts/TeamContext';
import { PageTitle, ToolbarContainer } from '../layouts/AdminLayout.styled';
import { TeamModel, teamOptions, TeamResponse, TeamType } from '../types/team';
import { deleteItem, insertItem, updateItem } from '../utils/tableUtils';
import { getTeamTypeDisplayValue } from '../utils/teamUtils';
import './ManageTeams.scss';

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

const openNotification = (statusCode: number, message: string) => {
  const ERROR_MESSAGE = message === 'DuplicateTeamName' ? DUPLICATE_TEAM_MESSAGE : GENERIC_MESSAGE;
  notification.error({
    message: `Error: ${statusCode}`,
    description: `${ERROR_MESSAGE}`,
    duration: 2,
  });
};
//=====================================================================

const { Search } = Input;
const getParentType = (type: TeamType): TeamType[] => {
  switch (type) {
    case 'Team':
      return ['Engagement'];

    case 'Engagement':
      return ['Subcontractor', 'Department'];

    case 'Subcontractor':
      return ['Program'];

    case 'Department':
      return ['Program'];
    default:
      return [];
  }
};

export const ManageTeams = () => {
  const { teams: data, setTeams: setData } = useContext(TeamContext);
  const [addTeamDialog, showAddTeamDialog] = useState(false);
  const [form] = Form.useForm<TeamModel>();
  const [activeType, setActiveType] = useState<TeamType>('Team');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchEl = useRef(null);
  const [hasError, setHasError] = useState<Response>();
  const [page, setPage] = useState(1);

  const getColumnSearchProps = useCallback(
    (dataIndex) => ({
      // eslint-disable-next-line react/prop-types
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 5 }}>
            <Search
              ref={searchEl}
              allowClear
              onChange={(e: any) => {
                if (e._reactName === 'onChange') {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                } else {
                  clearFilters();
                }
              }}
              placeholder="Filter By Name"
              onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              value={selectedKeys[0]}
              style={{ width: 200 }}
            />
          </div>
        );
      },
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchEl.current.select(), 100);
        }
      },
      render: (text) => {
        return text;
      },
    }),
    []
  );

  const columns: EditableColumn<TeamResponse>[] = useMemo(
    () => [
      {
        title: '#',
        width: '10px',
        render: (value, record, index) => (page - 1) * USER_PAGE_NUMBER + index + 1,
        align: 'center',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        editable: true,
        rules: [{ required: true, message: 'This field is required' }],
        ...getColumnSearchProps('name'),
      },
      {
        title: 'Code',
        dataIndex: 'code',
        editable: true,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: (value, record) => getTeamTypeDisplayValue(record.type),
      },
      {
        title: 'Parents',
        dataIndex: 'parentIds',
        editable: true,
        inputType: 'select',
        inputProps: {
          mode: 'multiple',
        },
      },
    ],
    [page, getColumnSearchProps]
  );
  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    if (!data) {
      return;
    }

    const newColumns = [...columns];
    const index = newColumns.findIndex((item) => item.dataIndex === 'parentIds');
    const parentCol = newColumns[index];

    parentCol.options = data
      .filter((item) => getParentType(activeType).includes(item.type))
      .map((item) => ({ value: item.id, label: item.name }));
    parentCol.render = (value: string, record: TeamResponse) => (
      <>
        {record?.parentIds?.map((parentId) => (
          <Tag key={parentId}>{data.find((x) => x.id === parentId)?.name ?? ''}</Tag>
        ))}
      </>
    );

    setTableColumns(newColumns);
  }, [activeType, data, page, columns]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
  };

  const onSave = async (id: number, { code, name, parentIds, type }: TeamResponse) => {
    try {
      await updateTeam(id, { code, name, parentIds, type });
      const newData = updateItem({ id, code, name, type, parentIds }, data);
      setData(newData);
    } catch (error) {}
  };

  const onDelete = async (id: number) => {
    try {
      await deleteTeam(id);
      const newData = deleteItem(id, data);
      setData(newData);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    try {
      const { code, name, type, parentIds } = form.getFieldsValue();
      const newTeam = await createTeam({
        code,
        name,
        parentIds,
        type,
      });
      form.resetFields();
      const newData = insertItem(newTeam, data);
      setData(newData);
      showAddTeamDialog(false);
    } catch (error) {
      console.log(error);
      setHasError(error);
      openNotification(error.status, error.message);
    }
  };

  const tableData = data?.filter((item) => item.type === activeType);
  const onChangeTeamType = ({ target: { value } }: RadioChangeEvent) => {
    setActiveType(value);
  };

  const onValueChanges = () => {
    setHasError(undefined);
  };

  return (
    <Card className="page-manage-teams">
      <Helmet>
        <title>Manage Projects</title>
      </Helmet>
      <PageTitle>
        <Title level={3}>Manage Projects</Title>
      </PageTitle>
      <ToolbarContainer>
        <Row>
          <Col span={12}>
            <div>
              <Button
                type="primary"
                onClick={() => {
                  form.resetFields();
                  form.setFieldsValue({ type: activeType });
                  showAddTeamDialog(true);
                }}
              >
                Add New Project
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div className="team-type-button-group">
              <Radio.Group
                options={teamOptions}
                onChange={onChangeTeamType}
                value={activeType}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
          </Col>
        </Row>
      </ToolbarContainer>
      <EditableTable data={tableData} columns={tableColumns} onSave={onSave} onDelete={onDelete} onChange={onChange} />
      <Modal
        title="Add New Project"
        visible={addTeamDialog}
        onOk={onSubmit}
        onCancel={() => showAddTeamDialog(false)}
        destroyOnClose
      >
        <Form
          style={{ marginTop: '1rem' }}
          {...layout}
          form={form}
          name="basic"
          initialValues={{ type: activeType }}
          onValuesChange={onValueChanges}
        >
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Code" name="code">
            <Input />
          </Form.Item>
          <Form.Item label="Type" name="type" rules={[{ required: true, message: 'This field is required!' }]}>
            <Select
              options={teamOptions}
              onChange={() => form.setFieldsValue({ parentIds: null })}
              suffixIcon={<SearchOutlined />}
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) => {
              return prev.type !== curr.type || prev.parentId !== curr.parentId;
            }}
          >
            {() => (
              <Form.Item label="Parent" name="parentIds">
                <TeamSelect showSearch mode="multiple" type={getParentType(form.getFieldValue('type'))} />
              </Form.Item>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};
