import { Pie } from '@ant-design/charts';
import { Col, Empty } from 'antd';
import { chain } from 'lodash';
import { useContext } from 'react';
import {
  ABOVE_TARGET,
  BELOW_TARGET,
  COLOR_ABOVE_TARGET,
  COLOR_BELOW_TARGET,
  COLOR_ON_TARGET,
} from '../common/constants';
import { Card } from '../components/Card';
import { ProgramDataContext } from '../contexts/ProgramDataContext';
import { TeamContext } from '../contexts/TeamContext';
import { TeamScore } from '../types/metrics';
import { getChildrenIds } from '../utils/teamUtils';

const BELOW_TARGET_LABEL = 'Below Target';
const ON_TARGET_LABEL = 'On Target';
const ABOVE_TARGET_LABEL = 'Above Target';

const PerfPie = ({ scores }: { scores: TeamScore[] }) => {
  if (!scores?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  let scoresNotNull = scores.filter((item) => item.score != null);

  const scoresSummary = {
    belowTarget: scoresNotNull.filter(({ score }) => score >= 0 && score <= BELOW_TARGET).length,
    onTarget: scoresNotNull.filter(({ score }) => score > BELOW_TARGET && score <= ABOVE_TARGET).length,
    aboveTarget: scoresNotNull.filter(({ score }) => score > ABOVE_TARGET).length,
  };

  const data = [
    {
      label: BELOW_TARGET_LABEL,
      value: scoresSummary.belowTarget,
    },
    {
      label: ON_TARGET_LABEL,
      value: scoresSummary.onTarget,
    },
    {
      label: ABOVE_TARGET_LABEL,
      value: scoresSummary.aboveTarget,
    },
  ];

  return (
    <Pie
      data={data}
      height={300}
      angleField="value"
      colorField="label"
      color={({ label }) => {
        switch (label) {
          case BELOW_TARGET_LABEL:
            return COLOR_BELOW_TARGET;
          case ON_TARGET_LABEL:
            return COLOR_ON_TARGET;
          case ABOVE_TARGET_LABEL:
            return COLOR_ABOVE_TARGET;
          default:
            return '';
        }
      }}
      legend={{
        position: 'top',
      }}
    />
  );
};

export const SubcontractorProgramPerformances = () => {
  const { teams } = useContext(TeamContext);
  const { teamScores } = useContext(ProgramDataContext);

  if (!teamScores) return <></>;

  return (
    <>
      {chain(teamScores)
        .groupBy(
          (s) =>
            getChildrenIds(teams, 'Subcontractor').find((x) => x.teamIds.includes(s.teamId))?.parent ??
            'No Subcontractor'
        )
        .map((groupScores, key) => (
          <Col key={key} className="chart-Program" xs={24} sm={12} xxl={6}>
            <Card title={`${key} Project Performance`}>
              <PerfPie scores={groupScores} />
            </Card>
          </Col>
        ))
        .value()}
    </>
  );
};
