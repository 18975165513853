import { Breadcrumb, Menu, Spin } from 'antd';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchTeams } from '../apis/teamClient';
import { useFetch } from '../hooks/useFetch';
import { TeamResponse, TeamTypeWithDetails } from '../types/team';
import { Link } from 'react-router-dom';
import { getTeamTypeDisplayValue } from '../utils/teamUtils';

interface ITeamContext {
  teams: TeamResponse[];
  setTeams: (teams: TeamResponse[]) => void;
  renderParentCrumb: (teamId: number) => React.ReactNode;
}

export const TeamContext = createContext<ITeamContext>({
  teams: [],
  setTeams: () => {},
  // eslint-disable-next-line react/display-name
  renderParentCrumb: () => <></>,
});

interface IProps {
  children: React.ReactNode;
}

export const TeamContextProvider = ({ children }: IProps) => {
  const [teams, setTeams] = useState<TeamResponse[]>();
  const { data: originalData, error: fetchError } = useFetch(fetchTeams);

  useEffect(() => {
    setTeams(originalData);
  }, [originalData]);

  const renderParentCrumb = useCallback(
    (teamId: number) => {
      const targetTeam = teams.find((item) => item.id === teamId);

      if (!targetTeam) return <></>;

      if (targetTeam.parentIds?.length > 1) {
        const parentMenuItems = (
          <Menu>
            {targetTeam.parentIds.map((id) => {
              const t = teams.find((item) => item.id === id);
              let type = getTeamTypeDisplayValue(t.type);
              if (type === 'SCDM') type = 'Program';

              return (
                <Menu.Item key={id} title={`${type}: ${teams.find((item) => item.id === id)?.name}`}>
                  {TeamTypeWithDetails.includes(t.type) ? (
                    <Link to={`/${type.toLowerCase()}/${id}`} style={{ color: '#1890ff' }}>
                      {teams.find((item) => item.id === id)?.name} ({type})
                    </Link>
                  ) : (
                    <span>
                      {teams.find((item) => item.id === id)?.name} ({type})
                    </span>
                  )}
                </Menu.Item>
              );
            })}
          </Menu>
        );

        return (
          <Breadcrumb.Item overlay={parentMenuItems}>
            <span style={{ color: '#1890ff' }}>Parents</span>
          </Breadcrumb.Item>
        );
      }

      const parentTeam = teams.find((item) => item.id === targetTeam.parentIds?.[0]);
      let type = getTeamTypeDisplayValue(parentTeam.type);
      if (type === 'SCDM') type = 'Program';
      return (
        <Breadcrumb.Item>
          {TeamTypeWithDetails.includes(parentTeam.type) ? (
            <Link
              title={`${type}: ${parentTeam?.name}`}
              to={`/${type.toLowerCase()}/${parentTeam.id}`}
              style={{ color: '#1890ff' }}
            >
              {parentTeam?.name}
            </Link>
          ) : (
            <span title={`${type}: ${parentTeam?.name}`} style={{ color: '#1890ff' }}>
              {parentTeam?.name}
            </span>
          )}
        </Breadcrumb.Item>
      );
    },
    [teams]
  );

  const ctxValues = useMemo(() => ({ teams, setTeams, renderParentCrumb }), [renderParentCrumb, teams]);

  if (!teams) {
    return <Spin />;
  }

  if (fetchError) {
    return <div>{JSON.stringify(fetchError)}</div>;
  }

  return <TeamContext.Provider value={ctxValues}>{children}</TeamContext.Provider>;
};
