import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useFetch } from '../hooks/useFetch';
import { IProjectData, MeasureQuery, MonthlyData } from '../types/metrics';
import { DATE_ID_FORMAT, MIN_DATE_ID } from '../common/constants';
import { Spin } from 'antd';
import { fetchMonthlyScore, fetchScores, getChartNote } from '../apis/scoreClient';
import { TeamContext } from './TeamContext';
import { uniq } from 'lodash';
import { ChartNoteResponse } from '../types/note';

export const ProjectDataContext = createContext<IProjectData>(null);

interface IProps {
  projectId: string | number;
  children: React.ReactNode;
  dateId: string;
}

export const ProjectDataContextProvider = ({ dateId, projectId, children }: IProps) => {
  const query: MeasureQuery = {
    startDateId: moment(MIN_DATE_ID).format(DATE_ID_FORMAT),
    teamId: projectId,
  };
  // const { teams } = useContext(TeamContext);
  // teams.filter((item) => `${item.parentId}` === projectId);
  const { data: scores, loading, error } = useFetch(() => fetchScores(query), [projectId]);
  const { data: monthlyScores } = useFetch(() => fetchMonthlyScore(query), [projectId]);
  const { data: chartNoteOriginal } = useFetch(() => getChartNote(+projectId));
  const [chartNote, setChartNote] = useState<ChartNoteResponse[]>(chartNoteOriginal);

  const [formattedMonthlyData, setFormattedMonthlyData] = useState<MonthlyData[]>([]);
  const [filteredTargetDate, setFilteredTargetDate] = useState<number[]>([]);

  const dateKeys = useMemo(() => uniq([...(scores ?? [])].map((item) => item.dateId)).sort(), [scores]);
  const currentMonth = useMemo(() => scores?.find((item) => `${item.dateId}` === dateId), [scores, dateId]);

  useEffect(() => {
    setChartNote(chartNoteOriginal);
  }, [chartNoteOriginal]);

  useEffect(() => {
    if (!monthlyScores) return;

    setFormattedMonthlyData(
      monthlyScores
        .map((row) => {
          const elementData: MonthlyData = {
            type: row.name,
            label: row.name.concat(row.categoryName),
            theme: row.categoryName,
            order: row.categoryOrder,
            color: row.color,
          };

          dateKeys.forEach((date) => {
            const dataByDate = row.kpiData?.find((item) => item.dateId === date);
            elementData[date] = {
              score: dataByDate?.score,
              actual: dataByDate?.actual,
              target: dataByDate?.target,
              displayData: dataByDate?.displayData,
            };
          });

          return elementData;
        })
        .sort((a, b) => a.order - b.order)
    );

    setFilteredTargetDate(
      uniq(
        monthlyScores
          .flatMap((x) => x.kpiData)
          .filter((x) => x.target !== null)
          .map((x) => x.dateId)
      ).sort()
    );
  }, [dateKeys, monthlyScores]);

  const ctxValues = useMemo(
    () => ({
      scores,
      teamId: projectId,
      dates: dateKeys,
      currentMonth: currentMonth,
      monthlyData: formattedMonthlyData,
      targetDates: filteredTargetDate,
      chartNote,
      setChartNote,
    }),
    [chartNote, currentMonth, dateKeys, filteredTargetDate, formattedMonthlyData, projectId, scores]
  );

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (loading) {
    return <Spin />;
  }

  return (
    <Spin spinning={loading}>
      <ProjectDataContext.Provider value={ctxValues}>{children}</ProjectDataContext.Provider>
    </Spin>
  );
};
