import { Bar } from '@ant-design/charts';
import { Empty } from 'antd';
import { useContext, useMemo } from 'react';
import { ProgramDataContext } from '../contexts/ProgramDataContext';
import { customTargetLegend, getTargetColor, getTargetLabel } from '../utils/chartUtils';
import { TeamType } from '../types/team';

interface IProps {
  teamType: TeamType;
}
export const SolutionSetScores = ({ teamType }: IProps) => {
  const { departmentScores, subcontractorScores } = useContext(ProgramDataContext);

  const data = useMemo(() => {
    switch (teamType) {
      case 'Subcontractor':
        return subcontractorScores;
      case 'Department':
      default:
        return departmentScores;
    }
  }, [teamType, departmentScores, subcontractorScores]);

  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const processedData = data.map((item) => {
    return {
      ...item,
      category: getTargetLabel(Number(item.score.toFixed(2))),
    };
  });
  // processedData.map((item) => item.score === Number(item.score.toFixed(2)));
  return (
    <div className="project-metrics-container">
      <Bar
        height={300}
        data={processedData}
        xField="score"
        yField="name"
        seriesField="category"
        label={{
          position: 'middle',
        }}
        legend={{
          position: 'top',
          items: customTargetLegend,
        }}
        maxBarWidth={50}
        color={({ category }) => {
          return getTargetColor(category);
        }}
      />
    </div>
  );
};
