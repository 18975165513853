import { BookOutlined, PlusOutlined, BellOutlined, BellFilled } from '@ant-design/icons';
import { Drawer, Button, Row, Col, DatePicker, Divider, Modal, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { CreateNote } from './CreateNote';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { fetchNotesByTeam } from '../apis/noteClient';
import { sendNotiEngagement } from '../apis/teamClient';
import { NoteMode, NoteModel, NoteResponse, NoteTabKey } from '../types/note';
import Note from './Note';
import { StyledTabs, StyledTeamSelect } from './NoteDrawer.styled';
import { TeamSelect } from './TeamSelect';
import { TeamContext } from '../contexts/TeamContext';
import { UserContext } from '../contexts/UserContext';
import { Checkbox } from 'antd';
import { ROLE } from '../common/constants';
import { hasParentId } from '../charts/EngagementHeatmap';
import { AuthContext } from '../contexts/AuthContext';

interface IProps {
  teamId: number;
  month: Date;
  recordName: string;
  hasButtonText?: boolean;
  noti?: boolean;
}

const monthFormat = 'MMM YYYY';
export const CustomDrawer = ({ teamId, month, recordName, hasButtonText, noti }: IProps) => {
  //Const and var initialization
  const dateId = parseInt(moment(month).format('YYYYMM'));
  const initialNoteValue = { teamId: teamId, dateId: dateId, description: '', id: 0 };
  const { TabPane } = Tabs;
  //======================================================================================
  //Hooks
  const [visible, setVisible] = useState(false);
  const [createBox, setcreateBox] = useState(false);
  const [noteData, setNoteData] = useState<NoteResponse[]>([]);
  const [noteDetail, setNoteDetail] = useState<NoteModel>(initialNoteValue);
  const [mode, setMode] = useState<NoteMode>('create');
  const [allNoteshowed, setAllNoteshowed] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<number>(null);
  const [activeKey, setActiveKey] = useState(NoteTabKey.teams);
  const { teams } = useContext(TeamContext);
  const { users } = useContext(UserContext);
  const [isChecked, setIsChecked] = useState(false);
  const [notiChecked, setNotiChecked] = useState(noti);
  const [openSendMailModal, setOpenSendMailModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileSize, setMobileSize] = useState(false);
  const breakpoint = 415;
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    setMobileSize(width < breakpoint);
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  const {
    user: { id, isAdmin },
  } = useContext(AuthContext);

  //======================================================================================
  //Callbacks and APIs
  const getNoteByTeamId = async () => {
    try {
      const data = await fetchNotesByTeam({ teamIds: [teamId], dateId: dateId });
      setNoteData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllNote = async () => {
    try {
      const allNoteData = await fetchNotesByTeam({ teamIds: [], dateId: dateId });
      setNoteData(allNoteData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabChange = (activeKey) => {
    setActiveKey(activeKey);
    if (activeKey === NoteTabKey.all) {
      setAllNoteshowed(true);
      return;
    }
    setAllNoteshowed(false);
  };

  const handleCheckChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setNotiChecked(noti);
  }, [noti]);

  const openNotification = (statusCode: number, message: string) => {
    if (statusCode === 400) {
      notification.warning({
        message,
        duration: 3,
      });

      return;
    }

    notification.error({
      message: `Error: ${statusCode}`,
      description: message,
      duration: 2,
    });
  };

  const sendEmail = async () => {
    try {
      await sendNotiEngagement({ dateId: dateId, teamId: teamId });
      setNotiChecked(true);
      setOpenSendMailModal(false);
      notification.success({
        message: `Sending successfully`,
        duration: 2,
      });
    } catch (error) {
      openNotification(error.status, error.data?.error?.message);
    }
  };

  const checkSendEmail = async () => {
    if (notiChecked === false) {
      sendEmail();
    } else {
      setOpenSendMailModal(true);
    }
  };

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (!allNoteshowed) {
      getNoteByTeamId();
    } else {
      getAllNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, allNoteshowed]);

  const handleTeamChange = (value) => {
    setSelectedTeam(value);
  };

  const capgId = users?.filter((item) => item.roles.includes(ROLE.CAPG));

  const idArr = [];

  capgId.forEach((data) => idArr.push(data.id));

  const isEditable = !(id == '2');

  return (
    <>
      {hasButtonText ? (
        <Button
          className="note-filter-trigger-text"
          size="large"
          type="primary"
          onClick={async () => {
            setVisible(true);
          }}
          style={{ background: 'orange', borderColor: 'orange' }}
        >
          Note
        </Button>
      ) : (
        <>
          {isAdmin && noti !== undefined && (
            <>
              <Button
                className="noti-trigger"
                type="ghost"
                onClick={checkSendEmail}
                icon={notiChecked ? <BellFilled /> : <BellOutlined />}
                style={{ display: 'contents', color: 'orange' }}
              />
              <Modal
                title="Confirm sending notification"
                centered
                visible={openSendMailModal}
                onOk={sendEmail}
                onCancel={() => setOpenSendMailModal(false)}
              >
                <p>Do you want to resend the notification?</p>
              </Modal>
            </>
          )}
          <Button
            className="note-filter-trigger"
            type="ghost"
            onClick={async () => {
              setVisible(true);
            }}
            icon={<BookOutlined />}
            style={{ display: 'contents', color: 'orange' }}
          />
        </>
      )}

      <Drawer
        className="app-note-drawer"
        width={mobileSize ? window.innerWidth : 600}
        closable={mobileSize ? true : false}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <StyledTabs>
          <Tabs
            centered
            className="tabs-container"
            defaultActiveKey="1"
            size="small"
            style={{ marginBottom: 32 }}
            onChange={handleTabChange}
            activeKey={activeKey}
          >
            <TabPane
              className="single-tab"
              tab={<span title={`${recordName}'s Notes`}>{`${recordName}'s Notes`}</span>}
              key="1"
            >
              <Row className="filter-row" gutter={40}>
                <Col span={6} className="filter-row_element filter-row_padding">
                  <Title level={5} className="month-title">
                    Month
                  </Title>
                </Col>
                <Col span={9} className="filter-row_element filter-row_padding">
                  <DatePicker
                    value={moment(month)}
                    format={monthFormat}
                    allowClear={false}
                    picker="month"
                    disabled={true}
                  />
                </Col>
                <Col span={9} className="filter-row_padding">
                  {isEditable && !createBox && (
                    <Button
                      className="filter-row_btn"
                      type="link"
                      onClick={() => {
                        setNoteDetail(initialNoteValue);
                        setMode('create');
                        setcreateBox(true);
                      }}
                      icon={<PlusOutlined style={{ display: 'contents' }} />}
                    >
                      Add new notes
                    </Button>
                  )}
                </Col>
              </Row>

              <CreateNote
                isOpen={createBox}
                setVisible={setcreateBox}
                note={noteDetail}
                onChanged={getNoteByTeamId}
                mode={mode}
              />
              <div className="note-container">
                <h3>Notes</h3>
                {noteData.map((note) => {
                  return (
                    <>
                      <Note note={note} key={note.id} onChanged={getNoteByTeamId} isReplyable editable={isEditable} />
                      <div style={{ marginLeft: '30px' }} className="reply-note">
                        {note.listChildNote?.map((childNote) => {
                          return (
                            <>
                              <Note
                                note={childNote}
                                key={childNote.id}
                                onChanged={getNoteByTeamId}
                                editable={isEditable}
                              />
                            </>
                          );
                        })}
                      </div>
                    </>
                  );
                })}
              </div>
            </TabPane>

            <TabPane className="single-tab" tab="ALL NOTES" key="2">
              <Row align="middle" justify="center" className="">
                <Col className="" span={10}>
                  <h4 className="">Filter by</h4>
                </Col>
                <Col className="" span={14}>
                  <Checkbox style={{ paddingBottom: 6, fontSize: 12 }} onChange={handleCheckChange}>
                    {"Show only CAPG's comments"}
                  </Checkbox>
                </Col>
                <Col className="" span={24}>
                  <StyledTeamSelect>
                    <TeamSelect
                      type={['Department', 'Engagement']}
                      allowClear
                      placeholder="Filter by Prime"
                      onChange={handleTeamChange}
                    />
                  </StyledTeamSelect>
                </Col>
              </Row>
              <Divider />
              <Row>
                <div className="note-container">
                  <Row justify="space-between" className="filter-row" gutter={40}>
                    <Col>
                      <h3>Notes</h3>
                    </Col>
                    <Col span={9} className="filter-row_element">
                      <DatePicker
                        value={moment(month)}
                        format={monthFormat}
                        allowClear={false}
                        picker="month"
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  {isChecked
                    ? noteData
                        .filter(
                          (x) =>
                            !selectedTeam || x.teamId === selectedTeam || hasParentId(x.teamId, selectedTeam, teams)
                        )
                        .filter((x) => idArr.includes(x.creatorUserId))
                        .map((note) => {
                          const team = teams.find((item) => item.id === note.teamId);
                          return (
                            <>
                              <Note
                                title={team?.name}
                                note={note}
                                key={note.id}
                                onChanged={getAllNote}
                                isReplyable
                                editable={isEditable}
                              />
                              <div style={{ marginLeft: '30px' }} className="reply-note">
                                {note.listChildNote?.map((childNote) => {
                                  return (
                                    <>
                                      <Note
                                        note={childNote}
                                        key={childNote.id}
                                        onChanged={getAllNote}
                                        editable={isEditable}
                                      />
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          );
                        })
                    : noteData
                        .filter(
                          (x) =>
                            !selectedTeam || x.teamId === selectedTeam || hasParentId(x.teamId, selectedTeam, teams)
                        )
                        .map((note) => {
                          const team = teams.find((item) => item.id === note.teamId);
                          return (
                            <>
                              <Note
                                title={team?.name}
                                note={note}
                                key={note.id}
                                onChanged={getAllNote}
                                isReplyable
                                editable={isEditable}
                              />
                              <div style={{ marginLeft: '30px' }} className="reply-note">
                                {note.listChildNote?.map((childNote) => {
                                  return (
                                    <>
                                      <Note
                                        note={childNote}
                                        key={childNote.id}
                                        onChanged={getAllNote}
                                        editable={isEditable}
                                      />
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          );
                        })}
                </div>
              </Row>
            </TabPane>
          </Tabs>
        </StyledTabs>
      </Drawer>
    </>
  );
};
