/* eslint-disable react/display-name */
import { Row, Col, Radio, Button, Modal, Form, Input, Select, Tag, notification } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createKPI, deleteKPI, fetchKPI, updateKPI } from '../../apis/customMetric';
import { EditableColumn, EditableTable } from '../../components/EditableTable';
import { ErrorBox } from '../../components/ErrorBox';
import { useFetch } from '../../hooks/useFetch';
import { PageTitle } from '../../layouts/AdminLayout.styled';
import { Typography } from 'antd';
import {
  KPIResponse,
  KPIModel,
  MetricResponse,
  PosNegType,
  CategoryResponse,
  CategoryModel,
  IsPublicOptions,
} from '../../types/metrics';
import { SelectOptions } from '../../types/team';
import { deleteItem, insertItem, updateItem } from '../../utils/tableUtils';
import './KpiDefinition.scss';
import TextArea from 'antd/lib/input/TextArea';
import { createCategory, deleteCategory, fetchCategory, updateCategory } from '../../apis/fetchCategory';
import { SearchOutlined, TagFilled } from '@ant-design/icons';
import classNames from 'classnames';
const { Option } = Select;
const { Title } = Typography;
type TableType = 'Category' | 'KPI';

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

const tableOptions: SelectOptions<TableType>[] = [
  {
    label: 'Category',
    value: 'Category',
  },
  {
    label: 'KPI',
    value: 'KPI',
  },
];

const posNegOptions: SelectOptions<PosNegType>[] = [
  {
    label: 'Higher is better',
    value: 1,
  },
  {
    label: 'Lower is better',
    value: -1,
  },
];

const isPublicOptions: SelectOptions<IsPublicOptions>[] = [
  {
    label: 'True',
    value: 1,
  },
  {
    label: 'False',
    value: 0,
  },
];

const colorOptions = [
  { value: 'Red', label: '#ffccc7' },
  { value: 'Pink', label: '#fce4ec' },
  { value: 'Purple', label: '#c5cae9' },
  { value: 'Blue', label: '#bbdefb' },
  { value: 'Cyan', label: '#b2ebf2' },
  { value: 'Green', label: '#c8e6c9' },
  { value: 'Light Green', label: '#dcedc8' },
  { value: 'Yellow', label: '#feffda' },
  { value: 'Orange', label: '#ffe7ba' },
  { value: 'Brown', label: '#d7ccc8' },
];

const { Search } = Input;
export const KpiDefinition = () => {
  const [KPIData, setKPIData] = useState<KPIResponse[]>();
  const [CategoryData, setCategoryData] = useState<CategoryResponse[]>();
  const { data: originalKPIs, error: fetchKPIError } = useFetch(fetchKPI);
  const { data: originalCategories, error: fetchCategoryError } = useFetch(fetchCategory);
  const [addKPIDialog, showAddKPIDialog] = useState(false);
  const [addCategoryDialog, showAddCategoryDialog] = useState(false);
  const [formKPI] = Form.useForm<KPIModel>();
  const [formCategory] = Form.useForm<CategoryModel>();
  const [activeMetrics, setActiveMetrics] = useState<TableType>('Category');
  const history = useHistory();
  const [hasError, setHasError] = useState<Response>();
  const [categoryOption, setCategoryOption] = useState<any[]>();
  interface IColumn extends EditableColumn<MetricResponse> {
    title?: string;
    className?: string;
  }
  const searchEl = useRef(null);
  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder="Filter By Name"
            onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },

    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const KPIColumns: EditableColumn<KPIResponse>[] = [
    {
      title: 'KPI Name',
      dataIndex: 'name',
      inputType: 'text',
      editable: true,
      ...getColumnSearchProps('name'),
      render: (value, record) => (
        <span
          className={classNames({
            'text-error':
              record.isPenalty ?? originalCategories.find((item) => item.id === record.categoryId).isPenalty,
          })}
        >
          {value}
        </span>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'categoryId',
      inputType: 'select',
      options: categoryOption,
      render: (record) => <span>{categoryOption.find((item) => item.value === record)?.label}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      inputType: 'text',
      editable: true,
    },
    {
      title: 'Characteristic',
      dataIndex: 'posNegAttributeValue',
      inputType: 'select',
      options: posNegOptions,
      editable: true,
    },
    {
      title: 'Is Penalty?',
      dataIndex: 'isPenalty',
      inputType: 'select',
      inputProps: {
        allowClear: true,
      },
      options: isPublicOptions,
      editable: true,
      render: (value, record) => (
        <span
          className={classNames({
            'text-error':
              record.isPenalty ?? originalCategories.find((item) => item.id === record.categoryId).isPenalty,
          })}
        >
          {!!value ? value : <i>(Inherited)</i>}
        </span>
      ),
    },
    {
      title: 'IsPublic',
      dataIndex: 'isPublic',
      inputType: 'select',
      options: isPublicOptions,
      editable: true,
    },
  ];

  const CategoryColumns: EditableColumn<CategoryResponse>[] = [
    {
      title: 'Category Name',
      dataIndex: 'name',
      inputType: 'text',
      editable: true,
      render: (value, record) => <span className={classNames({ 'text-error': record.isPenalty })}>{value}</span>,
    },
    {
      title: 'Color',
      dataIndex: 'themeColor',
      inputType: 'colorSelect',
      options: colorOptions,
      editable: true,
    },
    {
      title: 'Is Penalty?',
      dataIndex: 'isPenalty',
      inputType: 'select',
      options: isPublicOptions,
      editable: true,
    },
    {
      title: 'Order',
      dataIndex: 'order',
      inputType: 'number',
      editable: true,
    },
  ];

  useEffect(() => {
    setKPIData(originalKPIs);
    setCategoryData(originalCategories);
  }, [originalKPIs, originalCategories]);

  useEffect(() => {
    setCategoryOption(CategoryData?.map((item) => ({ label: item.name, value: item.id })));
  }, [CategoryData]);

  if (fetchKPIError) {
    return <div>{JSON.stringify(fetchKPIError)}</div>;
  }
  if (fetchCategoryError) {
    return <div>{JSON.stringify(fetchCategoryError)}</div>;
  }

  const onDeleteCategory = async (id: number) => {
    try {
      await deleteCategory(id);
      const newData = deleteItem(id, CategoryData);
      setCategoryData(newData);
      const newkpidata = fetchKPI();
      setKPIData(await newkpidata);
    } catch (error) {
      openNotification(400, 'Delete Category failed!');
    }
  };

  const onSaveCategory = async (id: number, { name, themeColor, isPenalty, order }: CategoryResponse) => {
    try {
      await updateCategory(id, { name, themeColor, isPenalty, order });
      const newData = updateItem({ id, name, themeColor, isPenalty, order }, CategoryData);
      setCategoryData(newData);
    } catch (error) {
      error?.data?.error?.message && openNotification(400, error?.data?.error?.message);
    }
  };
  const onSubmitCategory = async () => {
    try {
      await formCategory.validateFields();
      const { name, themeColor, isPenalty, order } = formCategory.getFieldsValue();
      const newMetric = await createCategory({
        name,
        themeColor,
        isPenalty,
        order,
      });
      formCategory.resetFields();
      const newData = insertItem(newMetric, CategoryData);
      setCategoryData(newData);
      showAddCategoryDialog(false);
    } catch (error) {
      error?.data?.error?.message && openNotification(400, error?.data?.error?.message);
    }
  };

  const onDeleteKPI = async (id: number) => {
    try {
      await deleteKPI(id);
      const newData = deleteItem(id, KPIData);
      setKPIData(newData);
    } catch (error) {
      openNotification(400, 'Delete KPI failed!');
    }
  };

  const onSaveKPI = async (
    id: number,
    { name, categoryId, description, posNegAttributeValue, isPenalty, isPublic }: KPIResponse
  ) => {
    try {
      await updateKPI(id, { name, categoryId, description, posNegAttributeValue, isPenalty, isPublic });
      const newData = updateItem(
        { id, name, categoryId, description, posNegAttributeValue, isPenalty, isPublic },
        KPIData
      );
      setKPIData(newData);
    } catch (error) {
      error?.data?.error?.message && openNotification(400, error?.data?.error?.message);
    }
  };
  const onSubmitKPI = async () => {
    try {
      await formKPI.validateFields();
      const { name, categoryId, description, posNegAttributeValue, isPenalty, isPublic } = formKPI.getFieldsValue();
      const newMetric = await createKPI({
        name,
        categoryId,
        description,
        posNegAttributeValue,
        isPenalty,
        isPublic,
      });
      formKPI.resetFields();
      const newData = insertItem(newMetric, KPIData);
      setKPIData(newData);
      showAddKPIDialog(false);
    } catch (error) {
      error?.data?.error?.message && openNotification(400, error?.data?.error?.message);
    }
  };

  const openNotification = (statusCode: number, message: string) => {
    notification.error({
      message: `Error: ${statusCode}`,
      description: message,
      duration: 4,
    });
  };

  return (
    <div className="page-home page-container">
      <section className="section-container">
        <div className="heatmap-container">
          <Row>
            <PageTitle>
              <Title level={3}>KPI Definition</Title>
            </PageTitle>
          </Row>
          <Row className="filter-container">
            <Col className="heatmap-measure-options">
              <label className="heatmap-filter-label">Type</label>
              <Radio.Group
                options={tableOptions}
                onChange={({ target: { value } }) => setActiveMetrics(value)}
                value={activeMetrics}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
          </Row>
        </div>

        {activeMetrics === 'Category' && (
          <div>
            <Button type="primary" className="btn-add-new-item" onClick={() => showAddCategoryDialog(true)}>
              Add Category
            </Button>
            <EditableTable
              data={CategoryData}
              columns={CategoryColumns}
              onSave={onSaveCategory}
              onDelete={onDeleteCategory}
            />
          </div>
        )}
        {activeMetrics === 'KPI' && (
          <div>
            <Button className="btn-add-new-item" type="primary" onClick={() => showAddKPIDialog(true)}>
              Add KPI
            </Button>
            <EditableTable data={KPIData} columns={KPIColumns} onSave={onSaveKPI} onDelete={onDeleteKPI} />
          </div>
        )}
        <Modal title="Add New KPI" visible={addKPIDialog} onOk={onSubmitKPI} onCancel={() => showAddKPIDialog(false)}>
          <ErrorBox error={hasError} message="The KPI name already exist." />
          <Form
            {...layout}
            className="add-modal"
            form={formKPI}
            name="basic"
            initialValues={{ remember: true, posNegAttributeValue: 1 }}
          >
            <Form.Item label="KPI name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Category Type"
              name="categoryId"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select options={categoryOption} />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <TextArea />
            </Form.Item>
            <Form.Item
              label="Characteristic"
              name="posNegAttributeValue"
              rules={[
                {
                  required: true,
                  message: 'The value must be (-1) or (1)!',
                },
              ]}
            >
              <Select options={posNegOptions} defaultValue="1" />
            </Form.Item>
            <Form.Item
              label="Is Public"
              name="isPublic"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={isPublicOptions} />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Add New Category"
          visible={addCategoryDialog}
          onOk={onSubmitCategory}
          onCancel={() => showAddCategoryDialog(false)}
        >
          <ErrorBox error={hasError} message="The category name already exist." />
          <Form {...layout} className="add-modal" form={formCategory} name="basic">
            <Form.Item
              label="Category name"
              name="name"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Color"
              name="themeColor"
              rules={[
                {
                  required: true,
                  message: 'Choose one color',
                },
              ]}
            >
              <Select>
                {colorOptions.map((x) => {
                  return (
                    <Option key={x.label} value={x.label} style={{ backgroundColor: x.label }}>
                      <span>{x.value}</span>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </section>
    </div>
  );
};
