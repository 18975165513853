import { AuditedEntity, Entity } from './entity';
import { SelectOptions, TeamResponse, TeamType } from './team';
import { ChartNoteResponse } from '../types/note';

export type MetricsType = 'Category' | 'KPI' | 'Sentiment';

export const measureOptions: SelectOptions<MetricsType>[] = [
  { label: 'Category', value: 'Category' },
  { label: 'KPI', value: 'KPI' },
  // { label: 'Sentiment', value: 'Sentiment' },
];

export type MetricOptionsType = 'Standard' | 'Custom Metric';

export const metricTypeOptions: SelectOptions<MetricOptionsType>[] = [
  { label: 'Standard', value: 'Standard' },
  { label: 'Custom Metric', value: 'Custom Metric' },
];

export interface Measure {
  dateId: number;
  team: null;
  teamId: number;
  kpiScore: ScoreCardData[];
  npsPerformance: number;
  promoterCount: number;
  detractorCount: number;
  passiveCount: number;
  tssGrowingCount: number;
  tssBuildingCount: number;
  tssSolidCount: number;
  tssStrongCount: number;
  tssLeadingCount: number;
  tssPerformance: number;
  npsMoM?: number;
}

export interface Score {
  score: number;
  promoterCount: number;
  passiveCount: number;
  detractorCount: number;
  npsPerformance: number;
  tssGrowingCount: number;
  tssBuildingCount: number;
  tssSolidCount: number;
  tssStrongCount: number;
  tssLeadingCount: number;
  tssPerformance: number;
}

export interface CategoryTeamData {
  teamId: number;
  categoryId: number;
  category: CategoryResponse;
  score: number;
}

export interface KpiTeamData {
  teamId: number;
  categoryId: number;
  categoryName: string;
  kpiId: number;
  kpiName: string;
  score: number;
  target: number;
  actual: number;
  kpi: KPIResponse;
}

export interface AggregatedScore extends ScoreCardData {
  scoreMoM?: number;
  previousScore?: number;
}

export interface TeamScore extends AggregatedScore {
  name: string;
  type: TeamType;
}

export interface MeasureResponse extends Measure, AuditedEntity {
  id: number;
  dateId: number;
  teamId: number;
}

export interface AggregatedMeasure extends Measure, AuditedEntity {
  npsMoM?: number;
  previousNPS?: number;
}

export interface TeamMeaSure {
  name: string;
  type: TeamType;
}

export interface PageResult<T> {
  totalCount: number;
  items: T[];
}

export interface PageRequest {
  currentPage?: number;
  pageSize?: number;
  sortDirection?: 'ascend' | 'descend' | '';
  sortColumn?: string;
}

export interface TargetRequestPaging extends PageRequest {
  teamName?: string;
}

export interface ScoreResponse extends Score, AuditedEntity {
  id: number;
  dateId: number;
  teamId: number;
}

export interface ScoreCardData extends ScoreResponse {
  categoryData: CategoryTeamData[];
  kpiData: KpiTeamData[];
}

export interface IProgramData {
  programScore: TeamScore;
  departmentScores: TeamScore[];
  subcontractorScores: TeamScore[];
  engagementScores: TeamScore[];
  teamScores: TeamScore[];
}
export interface IProgramTrendingData {
  programDatas: TeamScore[];
  departmentDatas: TeamScore[];
  subcontractorDatas: TeamScore[];
  engagementDatas: TeamScore[];
  teamDatas: TeamScore[];
}

export interface ChildMonthlyMeasure {
  actual: number;
  target: number;
  score: number;
  name: string;
  teamId: number;
}
export interface MonthlyMeasure {
  actual: number;
  target: number;
  score: number;
  displayData?: string;
  children?: Record<string, ChildMonthlyMeasure>;
}

export interface MonthlyData extends Record<number, MonthlyMeasure> {
  type: string;
  label: string;
  theme: string;
  color: string;
  isCustomMetric?: boolean;
  order?: number;
}

export interface MonthlyTarget {
  target: number;
  type: string;
  theme: string;
  color: string;
}

export interface MonthlyTargetData {
  dateId: number;
  data: Record<string, MonthlyTarget>;
}

export interface TargetFormat {
  teamId: number;
  dateId: number;
  kpiData: Record<string, number>[];
}

export interface ProjectCustomMetricsResponse {
  teamId: number;
  dateId: number;
  actual: number;
  target: number;
  score: number;
  metricName: string;
  metricId: number;
  theme: string;
}

export interface IProjectData {
  teamId: string | number;
  currentMonth: AggregatedScore;
  monthlyData: MonthlyData[];
  dates: number[];
  scores: AggregatedScore[];
  targetDates: number[];
  chartNote?: ChartNoteResponse[];
  setChartNote?: (chartNote: ChartNoteResponse[]) => void;
}

export interface ScoreQuery extends MeasureQuery {
  teamTypes?: string[];
}

export interface SentimentMetricResponse {
  name: string;
  PosNegAttributeValue: number;
}

export interface SentimentMetricFactResponse {
  sentimentId: number;
  teamId: number;
}

export interface SentimentHeatmap {
  sentiment: number[];
  teamId: number;
}

export interface MeasureQuery {
  teamId?: string | number | string[] | number[];
  startDateId?: string | number;
  endDateId?: string | number;
  parentId?: string | number;
  monthsLimit?: number;
}

export interface ITargetData {
  dates: number[];
  targets: MeasureResponse[];
}

export type CustomMetricType =
  | 'CodingPerformance'
  | 'Relationship'
  | 'Financial'
  | 'Risk'
  | 'People'
  | 'Performance'
  | 'Quality';
export type PosNegType = -1 | 1;
export type ConfiguredType = true | false;
export type IsPublicOptions = 1 | 0;
export type PosNegSentimentType = -1 | 0 | 1;

export interface SentimentMetricResponse extends AuditedEntity {
  name: string;
  posNegAttributeValue: PosNegSentimentType;
}

export interface SentimentMetricModel {
  name: string;
  posNegAttributeValue: PosNegSentimentType;
}

export interface SentimentFactResponse extends AuditedEntity {
  sentimentId: number[];
  teamId: number;
  teamName: string;
}

export interface SentimentScore {
  id: number;
  name: string;
  posNeg: number;
  score: number;
}

export interface SentimentScoreByDepartment {
  id: number;
  name: string;
  countPos: number;
  countNeutral: number;
  countNeg: number;
}
export interface ClientSentiment {
  clientSentimentScore: number;
  clientSentimentPos: number;
  clientSentimentNeg: number;
  clientSentimentNeutral: number;
}
export interface SentimentNPSChartResponse {
  clientSentiment: ClientSentiment;
  clientSentimentScoreMoM: number;
  sentimentCategoriesByDepartment: SentimentScoreByDepartment[];
  sentimentCategoriesByTeam: SentimentScore[];
}

export interface SentimentNpsRequest {
  startDateId?: string | number;
  endDateId?: string | number;
  monthsLimit?: number;
}

export interface CategoryResponse extends AuditedEntity {
  name: string;
  themeColor: string;
  isPenalty: number;
  order: number;
}

export interface CategoryWeightResponse {
  categoryId?: number;
  name: string;
  themeColor: string;
  weight: number;
  teamId: number;
  dateId: number;
  isPenalty?: number;
}

export interface CategoryWeightModel {
  id: number;
  name: string;
  weight: number;
  checked: boolean;
  isPenalty?: number;
}

export interface CategoryModel {
  name: string;
  themeColor: string;
  isPenalty: number;
  order: number;
}
export interface CategoryWeightModelResponse {
  categoryId: number;
  weight: number;
}
export interface KPIResponse extends AuditedEntity {
  name: string;
  categoryId: number;
  description: string;
  posNegAttributeValue: PosNegType;
  isPenalty?: number;
  isPublic: number;
}
export interface KPIWeightResponse {
  metricId: number;
  name: string;
  categoryId: number;
  categoryName: string;
  categoryOrder: number;
  weight: number;
  color?: string;
  description?: string;
  dateId: number;
  isPenalty?: number;
}

export interface KPISearchTeamsResponse {
  categoryId: Number;
  categoryName: string;
  kpiId: Number;
  kpiName: string;
  desciption: string;
  teamIds: Number[];
}
export interface KPIWeightModel {
  id: number;
  name: string;
  categoryId: number;
  weight: number;
  checked: boolean;
  isPenalty?: number;
}

export interface KPIWeightModelResponse {
  metricId: number;
  categoryId: number;
  weight: number;
}
export interface KPIModel {
  name: string;
  categoryId: number;
  description: string;
  posNegAttributeValue: PosNegType;
  isPenalty?: number;
  isPublic: number;
}

export interface KPIResponseModel {
  name: string;
  categoryId: number;
  description: string;
  posNegAttributeValue: PosNegType;
  isPenalty?: number;
  isPublic: number;
}

export interface MetricResponse extends ProjectMetricResponse {
  metricId: string;
  metric?: string;
  index: number;
}

export interface ProjectMetricResponse extends ProjectSpecificMetricResponse {
  CodingPerformance: number[];
  Financial: number[];
  People: number[];
  Relationship: number[];
  Risk: number[];
  Performance: number[];
  Quality: number[];
}

export interface ProjectSpecificMetricResponse extends Entity {
  teamId: number;
  team: TeamResponse;
  projectMetricts: ProjectMetricResponse;
}

interface MetricRequest {
  metrictId: number;
  index: number;
}

export interface ProjectMetricRequest {
  teamId: number;
  projectMetricts: {
    CodingPerformance?: number[];
    Financial?: number[];
    People?: number[];
    Relationship?: number[];
    Risk?: number[];
    Performance?: number[];
    Quality?: number[];
  };
}

export interface ProjectMetricFactResponse extends Entity {
  teamId: number;
  team: TeamResponse;
  dateId: number;
  projectSpecific1_CodingPerformance: number;
  projectSpecific2_CodingPerformance: number;
  projectSpecific3_CodingPerformance: number;
  projectSpecific1_Financial: number;
  projectSpecific2_Financial: number;
  projectSpecific3_Financial: number;
  projectSpecific1_People: number;
  projectSpecific2_People: number;
  projectSpecific3_People: number;
  projectSpecific1_Relationship: number;
  projectSpecific2_Relationship: number;
  projectSpecific3_Relationship: number;
  projectSpecific1_Risk: number;
  projectSpecific2_Risk: number;
  projectSpecific3_Risk: number;
  allowProjectSpecific1_CodingPerformance: boolean;
  allowProjectSpecific1_Financial: boolean;
  allowProjectSpecific1_People: boolean;
  allowProjectSpecific1_Relationship: boolean;
  allowProjectSpecific1_Risk: boolean;
  allowProjectSpecific2_CodingPerformance: boolean;
  allowProjectSpecific2_Financial: boolean;
  allowProjectSpecific2_People: boolean;
  allowProjectSpecific2_Relationship: boolean;
  allowProjectSpecific2_Risk: boolean;
  allowProjectSpecific3_CodingPerformance: boolean;
  allowProjectSpecific3_Financial: boolean;
  allowProjectSpecific3_People: boolean;
  allowProjectSpecific3_Relationship: boolean;
  allowProjectSpecific3_Risk: boolean;
}

export interface ProjectMetricFactRequest {
  projectSpecific1_CodingPerformance: number;
  projectSpecific2_CodingPerformance: number;
  projectSpecific3_CodingPerformance: number;
  projectSpecific1_Financial: number;
  projectSpecific2_Financial: number;
  projectSpecific3_Financial: number;
  projectSpecific1_People: number;
  projectSpecific2_People: number;
  projectSpecific3_People: number;
  projectSpecific1_Relationship: number;
  projectSpecific2_Relationship: number;
  projectSpecific3_Relationship: number;
  projectSpecific1_Risk: number;
  projectSpecific2_Risk: number;
  projectSpecific3_Risk: number;
}

export interface EngagementMonthlyScore extends KPIResponse {
  kpiData: EngagementScoreCardByDate[];
  categoryName: string;
  categoryOrder: number;
  color: string;
}

export interface EngagementScoreCardByDate extends KPIFactResponse {
  childrenData: KPIFactResponse[];
}

export interface KPIFactResponse extends KPIResponse {
  actual: number;
  target: number;
  score: number;
  teamId: number;
  dateId: number;
  displayData?: string;
}

export interface TeamMonthlyScore extends KPIResponse {
  kpiData: KPIFactResponse[];
  categoryName: string;
}
